<template>
	<div class="submodal-tbf submodal-calendar">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Key Result Frequency', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="container-content-modal">
			<div class="header-modal">
				<div class="title">{{ $t('key-result.frequency.title') }}</div>
				<div class="actions">
					<button class="btn-default-tbf reset-btn" @click="resetCalendar">{{ $t('calendar.period.reset') }}</button>
					<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="form-section">
				<div class="sub-header">
					<div class="left-box">
						<div class="description" v-bind:class="{error: errorValidation}">{{ $t('key-result.frequency.description') }}</div>
					</div>
				</div>

				<div class="input-group-tbf" v-if="loaded">
					<functional-calendar
						ref="CalendarFrequency"
						v-model="datesSelected"
						class="calendar-tbf frequency"
						:is-multiple-date-picker="true"
						:is-multiple="true"
						:calendars-count="3"
						:is-separately="false"
						:hidden-elements="['leftAndRightDays']"
						:limits="limitsRange"
						:newCurrentDate="newStartDay"
						:disabledDayNames="disabledDays"
						></functional-calendar>
				</div>

				<div class="summary-and-submit">
					<div class="box-card-tbf">
						<div class="total-updates">
							<div class="value">
								<img src="/build/icons/circle-updates.svg">
								{{ datesSelected.selectedDates.length }}
							</div>
							<div class="label">{{ $t('key-result.frequency.days') }}<br/>{{ $t('key-result.frequency.selected') }}</div>
						</div>
						<button class="button-tbf-blue button-submit" @click="saveModifications">
							<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
							{{ $t('key-result.frequency.save-frequency') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { required } from 'vuelidate/lib/validators'
	import IconPlus from "../Icons/Plus"

	export default {
		data() {
			return {
				loaded: false,
				editAction: false,
				totalDays: 0,
                dataCalendar: [],
                limitsRange: {
					min: '',
					max: ''
				},
				datesSelected: {
					selectedDates: []
				},
				calendarCounts: 1,
				errorValidation: false,
				newStartDay: '',
				disabledDays: [],
				arrayTranslations: [
					'key-result.frequency.title',
					'calendar.period.reset',
					'key-result.frequency.description',
					'key-result.frequency.days',
					'key-result.frequency.selected',
					'key-result.frequency.save-frequency'
				]
			};
		},
		props:{
			userWorkingDays: Array,
			startDate: String,
			endDate: String,
			measurements: Array
		},
		components:{
			IconPlus
		},
		validations: {
			datesSelected: {required}
		},
		created(){
			var start_date_moment = this.moment(this.startDate)
			var end_date_moment = this.moment(this.endDate)
			this.limitsRange.min = start_date_moment.format('D/M/YYYY')
			this.limitsRange.max = end_date_moment.format('D/M/YYYY')

			var monthsDiff = (end_date_moment.month() - start_date_moment.month()) + 1

			this.calendarCounts =  monthsDiff >= 3 ? 3 : monthsDiff

			var disabledDaysFiltered = this.moment.weekdaysMin(true)
			this.userWorkingDays.map(el => {
				disabledDaysFiltered.splice(disabledDaysFiltered.indexOf(this.$t('calendar.small_days.' + el)), 1)
			})

			this.newStartDay = new Date(this.startDate)

			this.disabledDays = disabledDaysFiltered

			setTimeout(() => {
				if(this.measurements.length){
					this.measurements.map( el => {
						if(el.date){
							this.datesSelected.selectedDates.push({date: el.date})
							// this.$refs.CalendarFrequency.ChooseDate(el.date)
						}else{
							var date = this.moment(el).format('D/M/YYYY')
							this.datesSelected.selectedDates.push({date: date})
						}
					})

					this.$refs.CalendarFrequency.ChooseDate(this.moment(this.startDate).format('D/M/YYYY'))
				}
			}, 0)
		},
		async mounted() {
			this.loaded = true
		},
		methods: {
			resetCalendar(){
				this.datesSelected.selectedDates = []
				setTimeout(() => {
					$('.vfc-hovered').removeClass('vfc-hovered')
				}, 0)
			},
			closeModal(){
				this.$emit("close_modal");
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')

				this.$v.$touch()

				if(!this.$v.datesSelected.$error && this.datesSelected.selectedDates.length){
					this.errorValidation = false
					$(e.target).attr('disabled', false)
					this.$emit("frequencyData", this.datesSelected.selectedDates);
				}else{
					this.errorValidation = true
					$(e.target).attr('disabled', false)
				}

			}
		}
	};
</script>